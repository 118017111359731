import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  navigateToPage,
  openLinkInNewTab,
  useWindowDimensions,
} from "../../helpers/helperMethods";
import { useHistory } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  // cardImg: { height: "40vh" },
  cartContent: {
    backgroundColor: "#f5f5f5",
  },
  cardActions: {
    // padding: "10px 5%",
  },
  smallDetails: { marginRight: "5px" },
}));

export default (props) => {
  const { content, onVote, votedCandidate, voting } = props;
  const { width } = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();

  const viewContestant = () => navigateToPage(`/${content.id}`, history);
  return (
    <Card
      className={classes.root}
      style={{
        height: width > 600 ? "50vh" : "56vh",
      }}
    >
      <CardActionArea
        onClick={
          //  viewContestant
          () => openLinkInNewTab(content.voteLink)
        }
      >
        <div
          style={{
            backgroundImage: `url("${content.imgUrl}")`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "30vh",
          }}
        ></div>
        <CardContent
          className={classes.cartContent}
          style={{
            height: width > 600 ? "8vh" : "10vh",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            flexWrap
            style={{ lineHeight: 1.2 }}
          >
            {content.name}
          </Typography>

          <Typography variant="subtitle2">Votes: {content.votes}</Typography>
          <br />
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button
          onClick={() =>
            // onVote(content)
            openLinkInNewTab(content.voteLink)
          }
          className={classes.cardVoteBtn}
          style={{
            display: "inline",
            width: "50%",
            backgroundColor: "#1876D1",
            color: "#fff",
            marginLeft: "25%",
          }}
        >
          {voting && votedCandidate.id === content.id ? (
            <PulseLoader size={7} margin={3} color={"#fff"} loading={voting} />
          ) : (
            "Vote"
          )}
        </Button>
      </CardActions>
    </Card>
  );
};
