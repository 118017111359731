import React, { useEffect, useState } from "react";
import ContestantsComponent from "../../components/contestants/ContestantsComponent";
import "../../../assets/styles/base.css";
import "../../../assets/styles/containers/contestants.css";
import "../../../assets/styles/plugins/pagination.css";
import { useDispatch, useSelector } from "react-redux";
import reduxAction from "../../../redux/action";
import {
  CONTESTANT_VOTES_PAYMENT,
  FETCH_CONTESTANTS,
  OTP_VERIFICATION,
  PAYMENT_VERIFICATION,
} from "../../../redux/contestants/types";
import Backdrop from "../../../components/Backdrop";
import Loading from "../../../components/Loading";
import PaymentModal from "../../components/payment/PaymentModal";
import { formatCategory } from "../../../helpers/helperMethods";
import ImageTextComponent from "../../components/common/ImageTextComponent";

export default () => {
  const totalPages = 5;
  const [state, setState] = useState({
    currentPage: 1,
    loading: false,
    voted: {},
    openModel: false,
    categories: {
      socialMedia: [],
      influencers: [],
      artCreative: [],
      lifestyle: [],
      journalists: [],
    },
  });

  const dispatch = useDispatch();
  const allContestants = useSelector((store) => store.contestants);
  const votePayment = useSelector((store) => store.votePayment);
  const paymentVerification = useSelector((store) => store.paymentVerification);

  useEffect(() => {
    if (!allContestants.data.results) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/votings/rwandainfluencerawards2021`,
          actionType: FETCH_CONTESTANTS,
        })
      );
    }
  }, []);

  const onVote = (candidate) => {
    setState({ ...state, voted: candidate, openModel: true });
  };

  const handleVotesPayment = (data) => {
    const { amount, phone, currency } = data;

    const paymentData = {
      amount: amount,
      phone_number: phone,
      currency,
      eventId: "rwandainfluencerawards2021",
      candidateId: state.voted.id,
    };

    dispatch(
      reduxAction({
        method: "POST",
        path: `/transactions`,
        actionType: CONTESTANT_VOTES_PAYMENT,
        data: paymentData,
      })
    );
  };

  const handleOTPSubmission = (otp) => {
    if (
      votePayment.payment_data.results &&
      votePayment.payment_data.results.charge.redirect
    ) {
      const otpData = {
        otp,
        redirect: votePayment.payment_data.results.charge.redirect,
      };

      dispatch(
        reduxAction({
          method: "POST",
          path: `/transactions/${votePayment.payment_data.results.transaction.tx_ref}/otp`,
          actionType: OTP_VERIFICATION,
          data: otpData,
        })
      );
    }
  };

  const checkPaymentIfSuccessful = () => {
    if (
      votePayment.payment_data.results &&
      votePayment.payment_data.results.transaction.tx_ref
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/transactions/${votePayment.payment_data.results.transaction.tx_ref}`,
          actionType: PAYMENT_VERIFICATION,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setState({ ...state, openModel: false });
  };

  const refreshCandidateVotes = () => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/votings/rwandainfluencerawards2021`,
        actionType: FETCH_CONTESTANTS,
      })
    );
  };

  const socialMedia = allContestants.data.results
    ? allContestants.data.results.candidates
        .filter((elt) => formatCategory(elt.category) === "socialMedia")
        .map((elt) => {
          return {
            id: elt.candidateId,
            name: `${elt.firstName} ${elt.lastName}`,
            imgUrl: elt.photo || "https://source.unsplash.com/random",
            voteLink: elt.voteLink,
            votes: elt.votes ? elt.votes : 0,
          };
        })
        .sort((a, b) => b.votes - a.votes)
    : [];

  const artCreative = allContestants.data.results
    ? allContestants.data.results.candidates
        .filter((elt) => formatCategory(elt.category) === "artCreative")
        .map((elt) => {
          return {
            id: elt.candidateId,
            name: `${elt.firstName} ${elt.lastName}`,
            imgUrl: elt.photo || "https://source.unsplash.com/random",
            voteLink: elt.voteLink,
            votes: elt.votes ? elt.votes : 0,
          };
        })
        .sort((a, b) => b.votes - a.votes)
    : [];

  const lifestyle = allContestants.data.results
    ? allContestants.data.results.candidates
        .filter(
          (elt) =>
            formatCategory(elt.category) === "lifestyle" ||
            formatCategory(elt.category) === "lifesytle"
        )
        .map((elt) => {
          return {
            id: elt.candidateId,
            name: `${elt.firstName} ${elt.lastName}`,
            imgUrl: elt.photo || "https://source.unsplash.com/random",
            voteLink: elt.voteLink,
            votes: elt.votes ? elt.votes : 0,
          };
        })
        .sort((a, b) => b.votes - a.votes)
    : [];

  const journalists = allContestants.data.results
    ? allContestants.data.results.candidates
        .filter((elt) => formatCategory(elt.category) === "journalists")
        .map((elt) => {
          return {
            id: elt.candidateId,
            name: `${elt.firstName} ${elt.lastName}`,
            imgUrl: elt.photo || "https://source.unsplash.com/random",
            voteLink: elt.voteLink,
            votes: elt.votes ? elt.votes : 0,
          };
        })
        .sort((a, b) => b.votes - a.votes)
    : [];

  const content = allContestants.data.results
    ? allContestants.data.results.candidates
        .filter((elt) => formatCategory(elt.category) === "content")
        .map((elt) => {
          return {
            id: elt.candidateId,
            name: `${elt.firstName} ${elt.lastName}`,
            imgUrl: elt.photo || "https://source.unsplash.com/random",
            voteLink: elt.voteLink,
            votes: elt.votes ? elt.votes : 0,
          };
        })
        .sort((a, b) => b.votes - a.votes)
    : [];

  const content2 = {
    content1: (
      <>
        <img
          src={socialMedia[0] && socialMedia[0].imgUrl}
          alt="customer care"
        />
      </>
    ),
    content2: (
      <>
        <p className="not-centered-title">RWANDA INFLUENCER AWARDS 2021</p>
        <br />
        <p className="not-centered-subtitle">
          Rwanda Influencer Awards aims to provide a well-rounded network and
          recognition for well-known and emerging influencers who use their
          platforms to positively impact people’s lives through their
          personalities, knowledge, and expertise. Voting is open from
          10/11/2021 and will end on 15/12/2021 at 23:59:59. The public votes
          will contribute to 60% of the total points.
        </p>
      </>
    ),
  };
  console.log(allContestants.data.results);
  return allContestants.loading && !allContestants.data.results ? (
    <Loading />
  ) : (
    <div>
      {/* <ImageTextComponent content={content2} /> */}
      {allContestants.loading && allContestants.data.results ? (
        <Backdrop />
      ) : (
        <div>
          <PaymentModal
            isOpen={state.openModel}
            handleClose={handleCloseModal}
            content={state.voted}
            onVotePayment={handleVotesPayment}
            votePaymentStore={votePayment}
            onOtpSubmit={handleOTPSubmission}
            voted={state.voted}
            paymentVerification={paymentVerification}
            checkPaymentIfSuccessful={checkPaymentIfSuccessful}
            refreshCandidateVotes={refreshCandidateVotes}
          />
          <ContestantsComponent
            onVote={onVote}
            votedCandidate={state.voted}
            voting={state.loading}
            socialMedia={socialMedia}
            artCreative={artCreative}
            lifestyle={lifestyle}
            journalists={journalists}
            content={content}
          />
        </div>
      )}
    </div>
  );
};
