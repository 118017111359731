import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useWindowDimensions } from "../../../helpers/helperMethods";
import mtn_logo from "../../../assets/images/payment-logos/mtn-logo.png";
import airtel_logo from "../../../assets/images/payment-logos/airtel-logo.jpeg";
import visa_logo from "../../../assets/images/payment-logos/visa-logo.jpeg";
import mastercard_logo from "../../../assets/images/payment-logos/mastercard-logo.jpeg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  supportedTitle: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #888888",
    lineHeight: "0.1em",
    margin: "10px 0 20px",
  },
  supportedInline: {
    background: "#fff",
    padding: "0 10px",
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default ({ onChoosePaymentMethod }) => {
  const classes = useStyles();
  const handlePaymentMethod = (text) => onChoosePaymentMethod(text);
  const { width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Typography variant={width > 600 ? "h4" : "h5"} align="center">
        Choose Payment Method
      </Typography>
      <div className={classes.inputElt}>
        <Typography variant="body1" className={classes.supportedTitle}>
          <span className={classes.supportedInline}>
            Supported Payment Methods
          </span>
        </Typography>
        <div className={classes.inputElt}>
          <Stack
            direction="row"
            spacing={width > 600 ? 4 : 1}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Avatar
              alt="Mtn"
              src={mtn_logo}
              sx={{
                width: width > 600 ? "20%" : 50,
                height: width > 600 ? "15vh" : 50,
                objectFit: "cover",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Airtel"
              src={airtel_logo}
              sx={{
                width: width > 600 ? "20%" : 50,
                height: width > 600 ? "15vh" : 50,
                objectFit: "cover",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("mobile")}
            />
            <Avatar
              alt="Visa"
              src={visa_logo}
              sx={{
                width: width > 600 ? "25%" : 50,
                height: width > 600 ? "15vh" : 50,
                objectFit: "cover",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("visa")}
            />
            <Avatar
              alt="MasterCard"
              src={mastercard_logo}
              sx={{
                width: width > 600 ? "25%" : 50,
                height: width > 600 ? "15vh" : 50,
                objectFit: "cover",
              }}
              variant="rounded"
              className={classes.cursor}
              onClick={() => handlePaymentMethod("visa")}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};
