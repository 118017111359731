import React from "react";
import { TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",

    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({ onInputChange, content, disabled, formError }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.inputElt}>
        <TextField
          id="email"
          label="Email"
          placeholder="Enter your email"
          onChange={onInputChange}
          value={content.email}
          disabled={disabled}
        />
      </div>

      <div className={classes.groupInputElt}>
        <Select
          id="currency"
          value={content.currency}
          name="currency"
          onChange={onInputChange}
          disabled={disabled}
        >
          <MenuItem value={"RWF"}>Rwf</MenuItem>
          <MenuItem value={"USD"}>USD</MenuItem>
        </Select>

        <FormControl fullWidth>
          <InputLabel id="amount-label">Votes</InputLabel>
          <Select
            labelId="amount-label"
            id="amount"
            value={content.amount}
            label="Amount"
            name="amount"
            onChange={onInputChange}
            disabled={disabled}
          >
            <MenuItem value={0}>
              <span className={classes.selectTitle}>Choose Votes</span>
            </MenuItem>
            <MenuItem value={content.currency === "RWF" ? 1000 : 1}>
              {content.currency === "RWF" ? "1000 Rwf" : "1 USD"}: 10 Votes
            </MenuItem>
            <MenuItem value={content.currency === "RWF" ? 5000 : 5}>
              {content.currency === "RWF" ? "5000 Rwf" : "5 USD"}:100 Votes
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <Typography className={classes.errorMsg} variant="subtitle1">
        {formError}
      </Typography>

      <br />
    </div>
  );
};
