import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const openLinkInNewTab = (url) =>
  window.open(url ? url : "https://demand.noneho.com/");

export const navigateToPage = (url, history) => history.push(url);

export const formatCategory = (stringTxt) => {
  if (stringTxt === "Art Creative") {
    return "artCreative";
  } else {
    return stringTxt.replace(/^./, stringTxt[0].toLowerCase());
  }
};
