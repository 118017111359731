import {
  FETCH_CONTESTANTS,
  FETCH_CONTESTANT,
  VOTE_CONTESTANT,
  CONTESTANT_VOTES_PAYMENT,
  OTP_VERIFICATION,
  PAYMENT_VERIFICATION,
} from "./types";

const initialState = {
  loading: false,
  data: {},
};

const initialState2 = {
  loading: false,
  payment_data: {},
  otp_data: {},
};

export default class Reducers {
  static getContestantsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_CONTESTANTS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_CONTESTANTS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_CONTESTANTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static getContestantReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_CONTESTANT}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_CONTESTANT}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_CONTESTANT}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static voteContestantReducer(state = initialState, action) {
    switch (action.type) {
      case `${VOTE_CONTESTANT}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${VOTE_CONTESTANT}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${VOTE_CONTESTANT}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static votePaymentReducer(state = initialState2, action) {
    switch (action.type) {
      case `${CONTESTANT_VOTES_PAYMENT}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${OTP_VERIFICATION}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${CONTESTANT_VOTES_PAYMENT}_FULFILLED`:
        return {
          ...state,
          loading: false,
          payment_data: action.payload,
        };

      case `${OTP_VERIFICATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          otp_data: action.payload,
        };

      case `${CONTESTANT_VOTES_PAYMENT}_REJECTED` ||
        `${OTP_VERIFICATION}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static paymentVerificationReducer(state = initialState, action) {
    switch (action.type) {
      case `${PAYMENT_VERIFICATION}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${PAYMENT_VERIFICATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${PAYMENT_VERIFICATION}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
