import { Button, Typography } from "@mui/material";
import React from "react";
import "../../assets/styles/components/card2.css";
import PulseLoader from "react-spinners/PulseLoader";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory } from "react-router";
import { navigateToPage } from "../../helpers/helperMethods";

export default (props) => {
  const { content, onVote, voting } = props;
  const history = useHistory();
  return (
    <div>
      <IconButton onClick={() => navigateToPage("/", history)}>
        <ChevronLeftIcon />
      </IconButton>
      <div className={"cardRoot"}>
        <div
          style={{
            backgroundImage: `url("${content.imgUrl}")`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: content.height || "60vh",
          }}
          id={"cardImg"}
        ></div>

        <div className={"cart-elt-2"}>
          <Typography variant="h6">{content.name}</Typography>
          <Typography variant="subtitle2">Votes: {content.votes}</Typography>
          <br />
          {/* <Typography variant="body2" color="text.secondary">
            <span
              style={{
                marginRight: "10px",
              }}
            >
              Age:{content.details && content.details.age}
            </span>

            <span
              style={{
                marginRight: "10px",
              }}
            >
              From: {content.details && content.details.origin}
            </span>
          </Typography> */}
          <Button
            onClick={() => onVote(content)}
            style={{
              display: "inline",
              padding: "15px",
              width: "25%",
              backgroundColor: "#1876D1",
              color: "#fff",
              marginTop: "5vh",
            }}
          >
            {voting ? (
              <PulseLoader
                size={7}
                margin={3}
                color={"#fff"}
                loading={voting}
              />
            ) : (
              "Vote"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
