import React from 'react';
import VerticalImageCard from '../../../components/Cards/verticalImageCard';
import { rest } from 'lodash';

export default (props) => {
  const { socialMedia, artCreative, lifestyle, journalists, content, ...rest } =
    props;

  return (
    <div className='contestants-root'>
      <p className='title'> All contestants</p>

      <div className='contestant-container'>
        <p className='not-centered-title'>Social Media</p>
        <div className='contestants'>
          {socialMedia.map((contestantElt) => (
            <VerticalImageCard
              key={contestantElt.id}
              isListElt
              content={contestantElt}
              {...rest}
            />
          ))}
        </div>
      </div>

      <div className='contestant-container'>
        <p className='not-centered-title'>Art Creative</p>
        <div className='contestants'>
          {artCreative.map((contestantElt) => (
            <VerticalImageCard
              key={contestantElt.id}
              isListElt
              content={contestantElt}
              {...rest}
            />
          ))}
        </div>
      </div>

      <div className='contestant-container'>
        <p className='not-centered-title'>Lifestyle Models</p>
        <div className='contestants'>
          {lifestyle.map((contestantElt) => (
            <VerticalImageCard
              key={contestantElt.id}
              isListElt
              content={contestantElt}
              {...rest}
            />
          ))}
        </div>
      </div>

      <div className='contestant-container'>
        <p className='not-centered-title'>Content Creators</p>
        <div className='contestants'>
          {content.map((contestantElt) => (
            <VerticalImageCard
              key={contestantElt.id}
              isListElt
              content={contestantElt}
              {...rest}
            />
          ))}
        </div>
      </div>

      <div className='contestant-container'>
        <p className='not-centered-title'>Journalists</p>
        <div className='contestants'>
          {journalists.map((contestantElt) => (
            <VerticalImageCard
              key={contestantElt.id}
              isListElt
              content={contestantElt}
              {...rest}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
