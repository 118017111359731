import React, { useEffect, useState } from "react";
import ContestantComponent from "../../components/contestants/ContestantComponent";
import Toast from "../../../components/MessageToast";
import "../../../assets/styles/base.css";
import "../../../assets/styles/containers/contestants.css";
import {
  FETCH_CONTESTANT,
  CONTESTANT_VOTES_PAYMENT,
  OTP_VERIFICATION,
  PAYMENT_VERIFICATION,
  FETCH_CONTESTANTS,
} from "../../../redux/contestants/types";
import { useDispatch, useSelector } from "react-redux";
import reduxAction from "../../../redux/action";
import Loading from "../../../components/Loading";
import apiService from "../../../services/apiService";
import PaymentModal from "../../components/payment/PaymentModal";

export default ({ contestantId }) => {
  const [state, setState] = useState({
    currentPage: 1,
    loading: false,
    voted: {},
    openModel: false,
  });

  const dispatch = useDispatch();

  const singleContestant = useSelector((store) => store.contestant);
  const votePayment = useSelector((store) => store.votePayment);
  const paymentVerification = useSelector((store) => store.paymentVerification);
  const contestant = singleContestant.data.results
    ? {
        id: contestantId,
        name: `${singleContestant.data.results.firstName} ${singleContestant.data.results.lastName}`,
        imgUrl:
          singleContestant.data.results.photo ||
          "https://pbs.twimg.com/profile_images/1120876686848008193/zcMv6ghc.jpg",
        votes: singleContestant.data.results.votes,
      }
    : {};

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/votings/rwandainfluencerawards2021/candidates/${contestantId}`,
        actionType: FETCH_CONTESTANT,
      })
    );
  }, []);

  const refreshCandidateVotes = () => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/votings/rwandainfluencerawards2021`,
        actionType: FETCH_CONTESTANTS,
      })
    );
  };

  const onVote = () => {
    setState({ ...state, voted: contestant, openModel: true });
  };

  const handleVotesPayment = (data) => {
    const { amount, phone, currency } = data;

    const paymentData = {
      amount: amount,
      phone_number: phone,
      currency,
      eventId: "rwandainfluencerawards2021",
      candidateId: state.voted.id,
    };

    dispatch(
      reduxAction({
        method: "POST",
        path: `/transactions`,
        actionType: CONTESTANT_VOTES_PAYMENT,
        data: paymentData,
      })
    );
  };

  const handleOTPSubmission = (otp) => {
    if (
      votePayment.payment_data.results &&
      votePayment.payment_data.results.charge.redirect
    ) {
      const otpData = {
        otp,
        redirect: votePayment.payment_data.results.charge.redirect,
      };

      dispatch(
        reduxAction({
          method: "POST",
          path: `/transactions/${votePayment.payment_data.results.transaction.tx_ref}/otp`,
          actionType: OTP_VERIFICATION,
          data: otpData,
        })
      );
    }
  };

  const checkPaymentIfSuccessful = () => {
    if (
      votePayment.payment_data.results &&
      votePayment.payment_data.results.transaction.tx_ref
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/transactions/${votePayment.payment_data.results.transaction.tx_ref}`,
          actionType: PAYMENT_VERIFICATION,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setState({ ...state, openModel: false });
  };

  return (singleContestant.loading && !singleContestant.data.results) ||
    (singleContestant.loading &&
      singleContestant.data.results &&
      singleContestant.data.results.candidateId !== contestantId) ? (
    <Loading />
  ) : (
    <div>
      <PaymentModal
        isOpen={state.openModel}
        handleClose={handleCloseModal}
        content={state.voted}
        onVotePayment={handleVotesPayment}
        votePaymentStore={votePayment}
        onOtpSubmit={handleOTPSubmission}
        voted={state.voted}
        paymentVerification={paymentVerification}
        checkPaymentIfSuccessful={checkPaymentIfSuccessful}
        refreshCandidateVotes={refreshCandidateVotes}
      />
      <ContestantComponent
        content={contestant}
        onVote={onVote}
        voting={state.loading}
      />
    </div>
  );
};
