import React, { useEffect, useState } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/core/ModalUnstyled";
import MobilePaymentForm from "./MobileForm";
import Toast from "../../../components/MessageToast";
import VisaForm from "./VisaForm";
import PaymentMethods from "./PaymentMethods";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import apiService from "../../../services/apiService";
import { IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { PulseLoader } from "react-spinners";
import CloseIcon from "@mui/icons-material/Close";
import { useWindowDimensions } from "../../../helpers/helperMethods";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: "50%",
  padding: "5vh 5%",
  height: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "6px",
  outLine: "none",
  "@media (max-width: 600px)": {
    width: "80%",
    height: "fit-content",
    padding: "10vh 5%",
  },
};

const submitBtnStyle = {
  padding: "15px 0",
  width: "50%",
  backgroundColor: "#1876D1",
  display: "inline",
  "&:hover": {
    background: "#1876D1",
    cursor: "pointer",
  },

  "@media (max-width: 600px)": {
    padding: "10px 0",
    width: "100%",
  },
};

const normalDivStyle = {
  padding: "10px 0",
  width: "100%",
  textAlign: "center",
};

export default ({
  isOpen,
  handleClose,
  content,
  onVotePayment,
  formError,
  votePaymentStore,
  onOtpSubmit,
  voted,
  refreshCandidateVotes,
  checkPaymentIfSuccessful,
  paymentVerification,
}) => {
  const [state, setState] = useState({
    phone: null,
    amount: 0,
    formError: null,
    otp: null,
    isCardForm: false,
    choosePaymentMethod: true,
    paymentMethod: null,
    currency: "RWF",
    email: null,
    isChecking: false,
    verificationInfo: {},
  });
  const showOtp =
    votePaymentStore.payment_data.results &&
    votePaymentStore.payment_data.results.charge.status === "success";

  useEffect(() => {
    setState({ ...state, formError });
  }, [formError]);

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;

    if (name) {
      return setState({ ...state, [name]: value, formError: "" });
    }

    setState({ ...state, [id]: value, formError: "" });
  };

  const handlePhoneChange = (phone) =>
    setState({ ...state, phone, formError: "" });

  const config = {
    public_key: "FLWPUBK-baf926e6ddfa3283a6d724a10598627b-X",
    tx_ref: Date.now(),
    amount: state.amount,
    currency: state.currency,
    payment_options: "card",
    customer: {
      email: state.email,
      phonenumber: "",
      name: `VoteId: ${Math.round(Math.random() * 10000000)}`,
    },
    customizations: {
      title: "Noneho.com | Payment For Vote",
      description: "Choose the payment method",
      logo: "https://demand.noneho.com/assets/img/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const onFormSubmit = () => {
    const { phone, amount, otp, email, paymentMethod } = state;

    if (showOtp && phone) {
      return onOtpSubmit(otp);
    }

    if (
      (paymentMethod === "mobile" && (amount === 0 || !phone)) ||
      (paymentMethod === "visa" && (amount === 0 || !email))
    ) {
      return setState({
        ...state,
        formError: `Please Make Sure all fields are field, Missing ${
          !amount
            ? "Votes"
            : paymentMethod === "mobile" && !phone
            ? "Phone Number"
            : "Email"
        }`,
      });
    }

    if (phone) {
      if (phone.length < 12) {
        return setState({
          ...state,
          formError: `Please Make Sure all The phone number written correctly`,
        });
      }
      const data = { amount, phone: `+${phone}`, currency: state.currency };
      return onVotePayment(data);
    }
    if (email && (!email.includes("@") || !email.includes("."))) {
      return setState({
        ...state,
        formError: `Please Make Sure all The email is written correctly`,
      });
    }
    handleFlutterPayment({
      callback: async (response) => {
        setState({ ...state, loading: true });
        const votingObj = {
          status: response.status,
          transId: response.transaction_id,
          tx_ref: response.tx_ref,
          amount: response.amount,
          currency: response.currency,
          customer: response.customer,
        };
        const voteResponse = await apiService({
          method: "PATCH",
          path: `/votings/rwandainfluencerawards2021/candidates/${voted.id}`,
          data: votingObj,
        });
        if (!voteResponse.error) {
          handleClose();
        }
        closePaymentModal();

        Toast({
          message: voteResponse.message,
          type: voteResponse.error ? "error" : "success",
        });

        setState({
          ...state,
          loading: voteResponse && false,
          paymentMethod: null,
        });
      },
      onClose: () => {},
    });
  };

  const onChoosePaymentMethod = (method) => {
    setState({ ...state, paymentMethod: method });
  };

  const onClosingModal = () => {
    setState({
      ...state,
      phone: null,
      amount: 0,
      formError: null,
      otp: null,
      isCardForm: false,
      choosePaymentMethod: true,
      paymentMethod: null,
      currency: "RWF",
      email: null,
    });
    handleClose();
  };
  const { width } = useWindowDimensions();
  const checkPayment = async () =>
    await apiService({
      method: "GET",
      path: `/transactions/${votePaymentStore.payment_data.results.transaction.tx_ref}`,
    });

  if (
    votePaymentStore.otp_data.status === 200 &&
    !state.isChecking &&
    !state.verificationInfo.status
  ) {
    setState({ ...state, isChecking: true });

    let checking = {};

    setInterval(async () => {
      checking = await checkPayment();
      const status = checking.results && checking.results.status;
      if (status === "successful") {
        setState({
          ...state,
          isChecking: false,
          verificationInfo: checking.results,
        });
        onClosingModal();
        Toast({
          message: checking.message,
          type: checking.error ? "error" : "success",
          onClose: () => {
            window.location.reload();
          },
        });
      }
      if (status === "failed" || status === "failure" || status === "fail") {
        setState({
          ...state,
          isChecking: false,
          verificationInfo: checking.results,
        });
        onClosingModal();
        Toast({
          message: checking.message,
          type: "error",
          onClose: () => {
            window.location.reload();
          },
        });
      }
    }, 5000);
  }

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={isOpen}
        onClose={onClosingModal}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <IconButton
            aria-label="Close"
            onClick={onClosingModal}
            style={{
              float: "right",
              margin: width > 600 ? "-3vh -3%" : "-8vh -3%",
            }}
          >
            <CloseIcon />
          </IconButton>
          {!state.paymentMethod ? (
            <PaymentMethods onChoosePaymentMethod={onChoosePaymentMethod} />
          ) : (
            <div>
              <Typography variant={width > 600 ? "h4" : "h5"}>
                Voting: {content.name || " ⚠️ Unset"}
              </Typography>
              <br />
              {state.paymentMethod === "mobile" ? (
                <>
                  {state.isChecking ? (
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="subtitle1">
                        Your payment is being proccessed, we will notify you
                        once done.
                      </Typography>
                      <CircularProgress
                        style={{
                          display: "inline-block",
                          padding: 30,
                          color: "#1876D1",
                        }}
                      />
                    </div>
                  ) : (
                    <MobilePaymentForm
                      onInputChange={handleInputChange}
                      onPhoneChange={handlePhoneChange}
                      content={state}
                      formError={state.formError}
                      disabled={
                        votePaymentStore.loading ||
                        !!votePaymentStore.payment_data.results
                      }
                      showOtp={showOtp}
                      otpData={votePaymentStore.otp_data}
                    />
                  )}
                </>
              ) : (
                <VisaForm
                  onInputChange={handleInputChange}
                  onPhoneChange={handlePhoneChange}
                  content={state}
                  formError={state.formError}
                  disabled={
                    votePaymentStore.loading ||
                    !!votePaymentStore.payment_data.result
                  }
                />
              )}
              <div style={normalDivStyle}>
                <Button
                  variant="contained"
                  onClick={onFormSubmit}
                  style={submitBtnStyle}
                  disabled={state.isChecking}
                >
                  {votePaymentStore.loading ? (
                    <PulseLoader
                      size={5}
                      margin={3}
                      color={"#fff"}
                      loading={votePaymentStore.loading}
                    />
                  ) : showOtp && state.phone ? (
                    "Submit OTP"
                  ) : (
                    "Proceed With Payment"
                  )}
                </Button>
              </div>
            </div>
          )}
        </Box>
      </StyledModal>
    </div>
  );
};
