import React, { useEffect, useState } from 'react';
import '../assets/styles/components/navbar.css';
import { useWindowDimensions } from '../helpers/helperMethods';
import { Icon } from '@iconify/react';
import menuAlt02 from '@iconify/icons-ci/menu-alt-02';
import closeFill from '@iconify/icons-eva/close-fill';
import { useHistory } from 'react-router';
import riawaedslogo from '../assets/images/logo.png';

export default () => {
  const history = useHistory();
  const dimension = useWindowDimensions();

  const [state, setState] = useState({
    menuHide: false,
    showMobileMenu: false,
  });

  const mobileMenuHandle = () => {
    setState((prevState) => ({
      ...state,
      showMobileMenu: !prevState.showMobileMenu,
    }));
  };

  const navigateTo = (url) => history.push(url);

  return (
    <div className='navRoot'>
      <div className='navContent'>
        <img src={riawaedslogo} style={{ height: 100 }} alt='RIA2021' />
        <div className='plugin'>
          <p>Powered by Inyarwanda.com</p>
        </div>
      </div>

      {/* <div className="navButton">
        <span
          className={dimension.width <= 600 ? "hideHumbuggerMenu" : "hide"}
          onClick={mobileMenuHandle}
        >
          {state.showMobileMenu ? (
            <Icon icon={closeFill} height={32} />
          ) : (
            <Icon icon={menuAlt02} id="humbugger" height={32} />
          )}
        </span>
      </div> */}
    </div>
  );
};
